<!-- 拒绝弹窗 -->
<template>
  <div>
    <el-dialog
      :z-index="10"
      :title="title"
      :visible.sync="visible"
      width="60%"
      @close="closeModal"
      :close-on-click-modal="false"
    >
      <!-- 左边竖线样式写法 -->
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        "
      >
        <div style="display: flex; align-items: center; margin-bottom: 2%">
          <div style="border-left: 4px solid #556bff">&nbsp;</div>
          <div class="search-title">基本信息</div>
        </div>
        <a-button v-if="isEdit" type="primary" @click="choosePeople"
          >选择居民</a-button
        >
      </div>

      <!-- 基本信息 -->
      <div
        v-if="noChoice"
        style="
          background-color: #fff8de;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 30px;
        "
      >
        <div style="margin-top: 6px; margin-bottom: 6px">
          &nbsp;
          <a-icon type="info-circle" style="color: #e6a23c" />请选择人员
        </div>
      </div>
      <ax-form v-else ref="form" :formBuilder="formBuilder1">
        <div slot="file">
          <imagePreview ref="img1" :businessId="businessId"></imagePreview>
        </div>
      </ax-form>
      <div style="display: flex; align-items: center; margin-bottom: 2%">
        <div style="border-left: 4px solid #556bff">&nbsp;</div>
        <div class="search-title">组织信息</div>
      </div>
      <!-- 组织信息 -->
      <ax-form ref="form1" :formBuilder="formBuilder2"></ax-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="onSubmit"
          :loading="saveType"
          style="background-color: #556bff"
          >保 存</el-button
        >
        <el-button @click="visible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  <script>
const formList1 = [
  {
    label: "姓名",
    type: "",
    model: "residentName",
    options: { allowClear: true, placeholder: "请输入" },
    col: { span: 12 },
    rules: [{ required: false, message: "请输入姓名" }],
  },
  {
    label: "身份证号",
    type: "",
    model: "identityCard",
    options: { allowClear: true, maxLength: 18, placeholder: "请输入" },
    col: { span: 12 },
    // rules: [
    //   {
    //     required: false,
    //     message: "请输入",
    //     pattern:
    //       /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{4}$/ |
    //       /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$/,
    //   },
    // ],
  },
  {
    label: "性别",
    type: "select",
    model: "gender",
    options: { disabled: true, placeholder: "请先填写身份证号" },
    col: { span: 12 },
    rules: [{ required: false, message: "请先填写身份证号" }],
  },
  {
    label: "年龄",
    type: "",
    model: "age",
    options: {
      disabled: true,
      placeholder: "请先填写身份证号",
    },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "出生日期",
    type: "datePicker",
    model: "birthDate",
    options: {
      disabled: true,
      placeholder: "请先填写身份证号",
    },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "联系电话",
    type: "",
    model: "phone",
    options: { placeholder: "请输入" },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "所属网格",
    type: "",
    model: "currentArea",
    options: {},
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "详细地址",
    type: "",
    model: "detailedAddress",
    options: { placeholder: "请选择详细地址" },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "个人照片",
    type: "",
    model: "file",
    options: {},
    col: { span: 12 },
    rules: [{ required: false, message: "" }],
  },
];
const formList2 = [
  {
    label: "拒绝理由",
    type: "textarea",
    model: "refusalReason",
    options: { placeholder: "请输入...",minRows: 4, },
    col: { span: 24 },
    rules: [{ required: true }],
  }
];
import api from "./api";
import imagePreview from "./image-preview/imagePreview";
export default {
  components: {
    imagePreview,
  },
  data() {
    return {
      options: [],
      visible: false,
      noChoice: true,
      isEdit: true,
      businessId: "",
      id: "",
      title: "",
      disabled: false,
      fileIds: [], //图片数组
      saveType: false,
      formBuilder1: this.$common.initGridFormData(
        formList1,
        { layout: "horizontal", labelWidth: 150, disabled: true },
        { col: { span: 8 } }
      ),
      formBuilder2: this.$common.initGridFormData(
        formList2,
        { layout: "horizontal", labelWidth: 150 },
        { col: { span: 8 } }
      ),
      baseInfoValue: {}, //基本信息
      familyInformation: {}, //组织信息
      selectedPeopleList: [],
    };
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    // 打开选择居民弹窗
    choosePeople() {
      this.$refs.choosePeople.openModal();
    },
    // 接收子组件传过来的peopleId
    receivePeopleId(item) {
      if (item[0]) {
        this.noChoice = false;
        this.getPersonInfo(item[0]);
        this.businessId = item[0];
        this.$nextTick(() => {
          this.$refs.img1.getPicList(item[0]);
        });
      }
    },
    // 打开弹窗
    openModal(record = {}) {
      this.visible = true;
      this.noChoice = true;
      this.isEdit = true;
      this.saveType = false;
      this.title = record.title;
      this.selectedPeopleList = record.selectedPeopleList;
      this.$nextTick(() => {
        // this.getDictionsary();
        // this.$refs.form1.resetFields()
      });
      if (record.id) {
        this.isEdit = false;
        this.noChoice = false;
        this.id = record.id;
        this.businessId = record.residentId;
        this.getMinorsInfo(record.id);
        this.getPersonInfo(record.residentId);
        this.$nextTick(() => {
          this.$refs.img1.getPicList(record.residentId);
        });
      } else {
        this.id = "";
      }
    },
    // 关闭弹窗
    closeModal() {
      this.visible = false;
      // this.$refs.form1.resetFields()
      this.$emit("refsh");
    },
    // 获取字典
    async getDictionsary() {
      // 未成年人家庭结构
      await api.dictData({ dicKind: "minors_family_structure" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.form1.setFormItemProp("familyStructure", {
          options: { options, allowClear: true, placeholder: "请选择" },
        });
      });
      // 未成年人家庭经济情况
      await api.dictData({ dicKind: "minors_family_finances" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.form1.setFormItemProp("familyFinances", {
          options: { options, allowClear: true, placeholder: "请选择" },
        });
      });
      // 未成年人不良行为类型
      await api
        .dictData({ dicKind: "minors_bad_behavior_type" })
        .then((res) => {
          const options = res.data.map((res) => {
            return { label: res.dicDesc, value: res.dicCode };
          });
          this.$refs.form2.setFormItemProp("badBehaviorType", {
            options: { options, allowClear: true, placeholder: "请选择" },
          });
        });
    },
    // 身份证号计算性别/年龄/出生日期
    calculateIDCardInfo(idCard) {
      let info = {
        gender: "",
        age: "",
        birthDate: "",
      };

      if (!idCard || (idCard.length !== 15 && idCard.length !== 18)) {
        return info;
      }

      let year =
        idCard.length === 15 ? "19" + idCard.substr(6, 2) : idCard.substr(6, 4);
      let month = idCard.substr(idCard.length === 15 ? 8 : 10, 2);
      let day = Number(idCard.substr(idCard.length === 15 ? 10 : 12, 2)) + 1;

      let genderCode =
        idCard.substr(idCard.length - 2, 1) % 2 === 0 ? "女" : "男";
      let birthday = new Date(year, parseInt(month) - 1, day); // 将月份减一
      let age = new Date().getFullYear() - birthday.getFullYear();
      info.gender = genderCode;
      info.age = age;
      info.birthDate = birthday.toISOString().slice(0, 10);
      this.$refs.form.setFieldsValue({ birthDate: info.birthDate });
      this.$refs.form.setFieldsValue({ gender: info.gender });
      this.$refs.form.setFieldsValue({ age: info.age });
      return info;
    },
    // 提交
    async onSubmit() {
      if (this.noChoice) {
        this.$message.warning("请先选择居民");
        return;
      }
      this.$refs.form.form.validateFields(async (err, value) => {
        if (err) return;
        this.baseInfoValue.residentId = this.businessId;
        if (this.$refs.form1) {
          this.$refs.form1.form.validateFields(async (err, value1) => {
            if (err) return;
            console.log(value1);
            this.familyInformation = value1;
            this.familyInformation.familyStructure =
              value1.familyStructure || "";
            this.familyInformation.familyFinances = value1.familyFinances || "";
            if (this.$refs.form2) {
              this.$refs.form2.form.validateFields(async (err, value2) => {
                if (err) return;
                this.BadInformation = value2;
                this.BadInformation.badBehaviorType =
                  value2.badBehaviorType || "";
                const totalValue = {
                  ...this.baseInfoValue,
                  ...this.familyInformation,
                  ...this.BadInformation,
                };
                console.log(totalValue, "375新增数据");
                this.saveType = true;
                if (this.id) {
                  totalValue.id = this.id;
                  const res = await api.updateMinors(totalValue);
                  console.log(res);
                  if (res.status === 200) {
                    this.$message.success("修改成功");
                    this.saveType = false;
                    this.closeModal();
                    this.$refs.form1.resetFields();
                  }
                } else {
                  const res = await api.addMinors(totalValue);
                  console.log(res);
                  if (res.status === 200) {
                    this.$message.success("新增成功");
                    this.saveType = false;
                    this.closeModal();
                    this.$refs.form1.resetFields();
                  }
                }
              });
            }
          });
        }
      });
    },
    // 根据id获取详情
    async getPersonInfo(id) {
      const res = await api.getResidentArchivesById(id);
      this.$refs.form.setFieldsValue(res.data);
      this.$refs.form.setFieldsValue({
        detailedAddress: res.data.cellName
          ? `${res.data.cellName}/${res.data.buildingNum}/${res.data.unitNum}/${res.data.floorNum}/${res.data.accountNum}`
          : "",
      });
      this.calculateIDCardInfo(res.data.identityCard);
    },
    // 根据id获取吸毒人员详情
    async getMinorsInfo(id) {
      const res = await api.getMinorsById(id);
      this.$refs.form1.setFieldsValue(res.data);
      this.$refs.form2.setFieldsValue(res.data);
    },
  },
};
</script>
  
  <style lang="less" scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}

.dialog-footer {
  display: flex;
  justify-content: end;
}

.search-title {
  // font-size: 0.072rem;
  font-weight: 800;
}
</style>