<!-- 党员审核表格  -->
<template>
  <div class="">
    <ax-table
      ref="tableBox"
      :columns="columns"
      :show-search="true"
      :searchForm="searchForm"
      @delect="del"
      :showToolBar="false"
      @action-column-click="actionColumnClick"
      @pop-confirm="popConfirm"
      :dataSourceApi="dataSourceApis"
      :dataSourceParams="dataSourceParams"
       :scroll="{ y: '55vh', x: '80vw' }"
    >
    </ax-table>
    <!-- 通过弹窗 -->
    <pass ref="passInfo"></pass>
    <!-- 拒绝弹窗 -->
    <refuse ref="refuseInfo"></refuse>
  </div>
</template>

<script>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import <组件名称> from '<组件路径>';
import pass from "./pass.vue";
const searchForm = [
  {
    label: "关键字",
    type: "input",
    model: "searchText",
    options: { placeholder: "请输入姓名/身份证号/籍贯..." },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "支部",
    type: "select",
    model: "searchText",
    options: { placeholder: "请选择" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "职务",
    type: "select",
    model: "searchText",
    options: { placeholder: "请选择" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "类型",
    type: "select",
    model: "searchText",
    options: { placeholder: "请选择" },
    formItem: {},
    col: { span: 6 },
  },
];

import api from "./api";
import Refuse from "./refuse.vue";
export default {
  components: { pass, Refuse },
  //import引入的组件需要注入到对象中才能使用
  data() {
    //这里存放数据
    return {
      api,
      options: [],
      activeTab: ["1"],
      visible: false,
      activeTabIndex: ["1"],
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "姓名",
            dataIndex: "residentName",
            ellipsis: false,
            width: 60,
          },
          {
            title: "性别",
            dataIndex: "gender",
            ellipsis: false,
            width: 40,
          },
          {
            title: "身份证号",
            dataIndex: "identityCard",
            ellipsis: true,
            width: 120,
          },
          {
            title: "所属支部",
            dataIndex: "residencePlace",
            ellipsis: false,
            width: 150,
          },
          {
            title: "职务",
            dataIndex: "nation_dictText",
            ellipsis: false,
            width: 80,
          },
          {
            title: "移除时间",
            dataIndex: "updateTime",
            ellipsis: false,
            width: 100,
          },
          {
            title: "操作人",
            dataIndex: "createBy",
            ellipsis: false,
            width: 100,
          },
          {
            title: "类型",
            dataIndex: "flag",
            ellipsis: false,
            width: 100,
          },
        ],
        false,
        {
          // fixed: "right",
          width: 180,
          // 操作部分
          actions: [
            {
              props: (record) => {
                return {
                  text: "通过",
                  name: "pass",
                  type: "#2A5CFF",
                  link: true,
                  popDisabled: false, //是否要弹窗,
                  // title: "确定恢复团员信息?",
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "拒绝",
                  name: "refuse",
                  type: "#F95A5A",
                  link: true,
                  popDisabled: false, //是否要弹窗,
                  // title: "确定恢复团员信息?",
                };
              },
            },
          ],
        }
      ),
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 70,
      }),
      selectedRowKeys: [],
      dataSourceApis: api.list,
      dataSourceParams: { type: "0" },
      currentAreaCode: "",
      currentArea: "",
      ageStart: "",
      ageEnd: "",
      currentAreaCode1: [],
      cascaderVisible: true, // 控制级联选择器的显示/隐藏
      type: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    onChange(checked) {
      console.log(`a-switch to ${checked}`);
    },
    // 表格操作
    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        // 通过
        case "pass":
          this.$refs.passInfo.openModal({ record, title: "党员审核通过" });
          break;
        // 拒绝
        case "refuse":
          this.$refs.refuseInfo.openModal({ record, title: "党员审核未通过" });
          break;
      }
    },
    // 根据id恢复团员
    async popConfirm({ record }) {
      const res = await api.deleteResidentArchivesById(record.id);
      if (res.status === 200) {
        this.$message.success("删除成功");
        this.$refs.tableBox.getDataSource();
      }
    },
    refsh() {
      this.$refs.tableBox.getDataSource();
    },
    // 批量删除
    del() {
      var that = this;
      if (that.selectedRowKeys.length === 0) {
        this.$message.warning("请勾选要删除的数据！");
      } else {
        this.$confirm({
          title: "是否删除",
          content: "确认是否删除当前选中内容",
          cancelText: "取消",
          okText: "确定",
          onOk: function () {
            api
              .deleteResidentArchivesByIds(that.selectedRowKeys.join(","))
              .then((res) => {
                that.$message.success("删除成功");
                that.$refs.tableBox.getDataSource();
              });
          },
        });
      }
    },
    onSelectChange(selectedRowKeys, row) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 获取字典
    async getDictionsary() {
      // 政治面貌
      await api.dictData({ dicKind: "political_status" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp(
          "politicalStatus",
          { options: { options, allowClear: true, placeholder: "请选择" } }
        );
      });
      // 所在小区
      await api.getGridManagement().then((res) => {
        const options = res.data.map((res) => {
          return { label: res.name, value: res.name };
        });
        this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp(
          "cellName",
          { options: { options, allowClear: true, placeholder: "请选择" } }
        );
      });
    },
  },
  //生命周期 - 创建完成(可以访问当前this实例)
  created() {},
  //生命周期 - 挂载完成(可以访问DOM元素)
  mounted() {
    // this.getDictionsary();
    // this.getRegionInfo();
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能,这个函数会触发
};
</script>
<style lang='less' scoped>
/deep/.ant-menu-item-selected {
  border-bottom: 2px solid #556bff !important;
  color: #556bff !important;
}

/deep/.ant-menu-item:hover {
  border-bottom: 2px solid #556bff !important;
  color: #556bff !important;
}
.grey {
  background-color: #e6e9ee;
  color: #2d3138;
  border: #e6e9ee;
  border-radius: 4px;
}
.green {
  background-color: #f6ffed;
  color: #389e0d;
  border-radius: 4px;
  border: #f6ffed;
}
/deep/ .ant-table-small.ant-table-bordered .ant-table-fixed-right {
  border-left: none;
}
</style>